body {
  margin: 0;
}

.current-word {
  border-radius: 3px;
}

.correct {
  color: green;
}

.danger {
  background-color: red;
}

.incorrect {
  color: red;
}
